<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';
export default {
    extends: Base,
    data(){
        return {
            Name:"BoDashboard",
        }
    },
    mounted(){
        this.$set(this.$root, 'page', this)
            this.refreshData()
        },
        methods:{}
    };
</script>
<template>
<div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Howdy Lingkar9</h5>
                    <p><strong>Selamat datang di Halaman Administrator Visi Viyata Lestari!.</strong></p>
                    <p>Anda berada di halaman TERPROTEKSI sistem Pengaturan Konten Website Visi Viyata Lestari.</p>

                    <p>Halaman ini khusus diperuntukkan HANYA untuk Administrator website yang akan melakukan pengelolaan konten.</p>
                    <p>Sebelum melakukan pengaturan, beberapa hal yang perlu diperhatikan : <br>
                    <ol>
                        <li>Simpan dan amankan Username dan Password Anda untuk menghindari hal-hal yang tidak diinginkan.</li>
                        <li>Siapkan dahulu materi /bahan yang akan digunakan dalam pengelolaan konten yang akan di update.</li>
                        <li>Siapkan pula foto, video atau material lain yang terkait, untuk memudahkan proses pengupdate-an.</li>
                        <li>Gunakan Browser dengan versi terbaru untuk mendapatkan compatibilitas fitur-fitur dalam backoffice ini.</li>
                    </ol>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>